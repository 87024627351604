import { useEffect, useState } from "react";

import { useMutation, useQuery } from "@apollo/client";
import clsx from "clsx";
import { Form, Formik, type FormikHelpers, getIn } from "formik";
import type { TFunction } from "i18next";
import { Form as Bsform, Modal } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { MdRingVolume } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { CONTACT_LIST_QUERY, CONTACT_MUTATION } from "@api/contacts/contacts";
import { showContactPath } from "@path/contact_paths";

import Loading from "@/containers/Loading";
import { setCallContact } from "@/store/acdReducer";
import { indexDate } from "@/utils/dates";

import { may } from "../../../abilities";
import { IncomingCallEvent } from "../../../actions/acdActions";
import { AddButton, CancelButton, SaveButton } from "../../../containers/buttons";
import ErrorMessage from "../../../containers/ErrorMessage";
import FormikCheck from "../../../containers/FormikCheck";
import FormikInput from "../../../containers/FormikInput";
import { NoDataTag } from "../../../containers/NoData";
import TTFormGroup from "../../../containers/TTFormGroup";
import handleError, { MutationError } from "../../../handleError";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import useDebounce from "../../../hooks/useDebounce";
import { getPeerNo } from "../../../new_utils";
import { sessionSelector } from "../../../store/sessionReducer";
import { addDangerFlash } from "../../flash/flashReducer";
import TTModal from "../../TTModal";
import type { ContactValuesInterface, ValuesInterface } from "./types";
import { ADDITIONAL_BUTTONS } from "./utils";

const validationSchema = (t: TFunction) =>
  Yup.object({
    isCompany: Yup.boolean(),
    salutation: Yup.string().when("isCompany", {
      is: (isCompany: boolean) => !isCompany,
      then: () => Yup.string().trim().required(t("contacts:form.salutation_missing")),
      otherwise: () => Yup.string().notRequired(),
    }),
    companyName: Yup.string().when("isCompany", {
      is: (isCompany: boolean) => !!isCompany,
      then: () => Yup.string().trim().required(t("contacts:form.salutation_missing")),
      otherwise: () => Yup.string().notRequired(),
    }),
    firstname: Yup.string().notRequired(),
    lastname: Yup.string().when(["isCompany", "firstname"], {
      is: (isCompany: boolean, firstname: string) => !isCompany && !firstname,
      then: () => Yup.string().trim().required(t("contacts:form.firstname_lastname_missing")),
      otherwise: () => Yup.string().notRequired(),
    }),
  });

const initialValues = () => ({
  isCompany: false,
  salutation: "",
  firstname: "",
  lastname: "",
  companyName: "",
});

export default function IncomingCall() {
  const dispatch = useAppDispatch();
  const [showIncomingModal, setShowIncomingModal] = useState(false);
  const [acknowledged, setAcknowledged] = useState(true);

  const session = useAppSelector(sessionSelector);
  const [call, setCall] = useState<Nullable<AcdCallEventType>>(null);
  const [notification, setNotification] = useState<Nullable<Notification>>(null);
  const [filterValue, setFilterValue] = useState("");
  const peerNo = call ? getPeerNo(call.event) : null;
  const navigate = useNavigate();

  const filter = useDebounce(filterValue);

  const { t } = useTranslation(["acd", "contacts", "translation"]);

  const [newButtonPressed, setNewButtonPressed] = useState(false);

  const [createContactMutation] = useMutation<ContactMutationInterface>(CONTACT_MUTATION);

  function toggleIncomingModal() {
    setShowIncomingModal(!showIncomingModal);
    setAcknowledged(showIncomingModal ? acknowledged : true);
  }

  function contactChosen(cnt: ContactInterface) {
    const cntSession = { ...session, currentProject: cnt.project };

    dispatch(setCallContact(cnt));
    navigate(showContactPath(cntSession, cnt));

    toggleIncomingModal();
  }

  function newNotification(call: AcdCallEventType) {
    const callIdentifier = "call_" + call.callGroupId;
    if (sessionStorage[callIdentifier]) {
      return null;
    }

    sessionStorage[callIdentifier] = JSON.stringify(call);
    const notification = new Notification(
      t("acd:incoming_call.incoming_call_notification", {
        line: call.line?.name,
        no: peerNo,
      }),
      {
        body: t("acd:incoming_call.incoming_call_notification_body", {
          greeting: call.line?.greeting,
        }),
        icon: "/apple-icon-152x152.png",
      },
    );

    notification.onclick = () => {
      notification.close();
      toggleIncomingModal();
    };
    return notification;
  }

  function incomingCallHandler(event: IncomingCallEvent) {
    const peerNo = getPeerNo(event.detail.event);
    if (!peerNo || !event.detail.line) {
      return;
    }

    setAcknowledged(false);
    setCall(event.detail);
    setFilterValue(
      peerNo
        .replace(/^00(?!4)/, "0")
        .replace(/^00/, "+")
        .replace(/^0/, "+49"),
    );

    setNotification(newNotification(event.detail));
  }

  function callEnded(ev: CustomEvent<AcdCallEventType>) {
    setAcknowledged(true);
    setCall(null);
    setFilterValue("");

    notification?.close();
    setNotification(null);
    const callIdentifier = "call_" + ev.detail.callGroupId;
    sessionStorage.removeItem(callIdentifier);
  }

  useEffect(() => {
    document.addEventListener("tt:incomingCall", incomingCallHandler);
    document.addEventListener("tt:callEnd", callEnded);

    return () => {
      document.removeEventListener("tt:incomingCall", incomingCallHandler);
      document.removeEventListener("tt:callEnd", callEnded);
    };
  });

  async function saveContact(values: ValuesInterface, { setSubmitting }: FormikHelpers<ValuesInterface>) {
    setSubmitting(true);

    const contactValues: ContactValuesInterface = {
      isCompany: !!values.companyName,
      companyName: values.companyName,
      infos: [],
    };

    if (contactValues.isCompany && (values.firstname || values.lastname)) {
      contactValues.children = [{ firstname: values.firstname, lastname: values.lastname }];
    } else if (!contactValues.isCompany) {
      contactValues.firstname = values.firstname;
      contactValues.lastname = values.lastname;
    }

    if (peerNo) {
      contactValues.infos.push({
        itype: values.companyName ? "WORK_PHONE" : "PRIVATE_PHONE",
        value: peerNo.replace(/^00(?!4)/, "0"),
      });
    }

    try {
      const { data } = await createContactMutation({
        variables: {
          contact: contactValues,
          customerId: session.currentCustomer.id,
          projectId: session.currentProject.id,
        },
      });

      if (!data?.mutateContact) {
        throw new MutationError();
      }

      contactChosen(data.mutateContact);
    } catch (e) {
      setSubmitting(false);
      handleError(e);
      dispatch(addDangerFlash(t("translation:global.general_error")));
    }
  }

  const { data, loading } = useQuery<ContactsDataInterface, ContactsQueryVarsInterface>(CONTACT_LIST_QUERY, {
    variables: {
      customerId: session.currentCustomer.id,
      projectId: session.currentProject.id,
      filters: {
        limit: "15",
        offset: "0",
        order: "UPDATED_AT",
        direction: "DESC",
        search: filter || filterValue,
        ctype: "MAIN",
        ignoreProject: true,
      },
    },
    skip: !showIncomingModal || !may(session, "contacts"),
  });

  const AdditionalButtons =
    getIn(ADDITIONAL_BUTTONS, [session.currentCustomer.identifier, session.currentProject.identifier]) || (() => <></>);

  return (
    <>
      {call && (
        <button
          type="button"
          className={clsx("TT-shortcut-incoming-call", { "not-acknowledged": !acknowledged })}
          onClick={toggleIncomingModal}
          title={t("acd:incoming_call.incoming_call")}
        >
          <MdRingVolume />
        </button>
      )}

      {call && (
        <TTModal show={showIncomingModal} onHide={toggleIncomingModal} size="lg">
          <Formik initialValues={initialValues()} validationSchema={validationSchema(t)} onSubmit={saveContact}>
            {({ values, isSubmitting }) => (
              <Form>
                <Modal.Header closeButton>
                  <Modal.Title>{t("acd:incoming_call.incoming_call")}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <Trans parent="p" t={t} i18nKey="acd:incoming_call.incoming_call_desc">
                    Eingehender Call für die Line „{{ line: call.line?.name || "" }}“ ({{ no: peerNo }}). Begrüßung:{" "}
                    <em>{{ greeting: call.line?.greeting || "" }}</em>
                  </Trans>

                  <TTFormGroup>
                    <Bsform.Label htmlFor="search">{t("acd:incoming_call.search_for_contact")}</Bsform.Label>
                    <Bsform.Control
                      id="search"
                      value={filterValue}
                      onChange={(ev) => setFilterValue(ev.target.value)}
                    />
                  </TTFormGroup>

                  {loading && <Loading />}

                  {!loading && !!data?.contacts && (
                    <ul className="TT-incoming-call-contacts-list">
                      <NoDataTag data={data.contacts} />

                      {data.contacts.map((cnt) => (
                        <li key={cnt.id} className="TT-incoming-call-contact-card" onClick={() => contactChosen(cnt)}>
                          <div className="contact-name">{cnt.displayName}</div>
                          <div className="contact-status">
                            {cnt.status ? cnt.status.name : t("contacts:no_state_chosen")}
                          </div>
                          <div className="project-name">{cnt.project.name}</div>
                          <div className="last-contact">
                            {t("contacts:list.last_contact")}{" "}
                            {cnt.lastContact ? indexDate(cnt.lastContact.insertedAt) : t("translation:global.never")}
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}

                  {newButtonPressed && (
                    <>
                      <fieldset>
                        <TTFormGroup>
                          <FormikCheck name="isCompany" id="is-company" label={t("contacts:company")} />
                        </TTFormGroup>

                        {values.isCompany && (
                          <TTFormGroup>
                            <Bsform.Label htmlFor="companyName">
                              {t("contacts:human_field_names.company_name")}
                            </Bsform.Label>
                            <FormikInput name="companyName" id="companyName" />
                            <ErrorMessage path="companyName" />
                          </TTFormGroup>
                        )}
                        {!values.isCompany && (
                          <>
                            <TTFormGroup>
                              <Bsform.Label htmlFor="salutation">
                                {t("contacts:human_field_names.salutation")}
                              </Bsform.Label>
                              <FormikInput name="salutation" id="salutation" />
                              <ErrorMessage path="salutation" />
                            </TTFormGroup>
                            <TTFormGroup>
                              <Bsform.Label htmlFor="firstname">
                                {t("contacts:human_field_names.firstname")}
                              </Bsform.Label>
                              <FormikInput name="firstname" id="firstname" />
                              <ErrorMessage path="firstname" />
                            </TTFormGroup>
                            <TTFormGroup>
                              <Bsform.Label htmlFor="lastname">{t("contacts:human_field_names.lastname")}</Bsform.Label>
                              <FormikInput name="lastname" id="lastname" />
                              <ErrorMessage path="lastname" />
                            </TTFormGroup>
                          </>
                        )}
                      </fieldset>
                    </>
                  )}
                </Modal.Body>

                <Modal.Footer>
                  {!newButtonPressed && (
                    <AddButton type="button" onClick={() => setNewButtonPressed(true)} size="sm">
                      {t("acd:incoming_call.create_contact")}
                    </AddButton>
                  )}

                  {newButtonPressed && (
                    <SaveButton disabled={isSubmitting} type="submit">
                      {t("translation:global.save")}
                    </SaveButton>
                  )}

                  <CancelButton type="button" size="sm" onClick={toggleIncomingModal} disabled={isSubmitting}>
                    {t("translation:global.cancel")}
                  </CancelButton>

                  <AdditionalButtons contactChosen={contactChosen} />
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </TTModal>
      )}
    </>
  );
}
