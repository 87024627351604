import { parsedDate } from "@/utils/dates";

export const cityCleanNextContactValidation = (
  contact: BaseContactInterface,
  lastCallContact: Nilable<BaseContactInterface>,
  questionnaire: Nullable<TaskInterface>,
  events: EventInterface[],
): [boolean, string] => {
  if (["Verifikation ausstehend", "Kontaktstopp"].includes(contact.status?.name || "") && !questionnaire) {
    return [false, "Bitte füllen Sie den Fragebogen aus!"];
  }

  const notReachedStates = ["falsche Daten", "keine valide Telefonnummer", "nicht erreichbar", "E-Rechnung verweigert"];

  if (notReachedStates.includes(contact.status?.name || "") && !questionnaire) {
    return [false, "Bitte füllen Sie den Datenabgleich der Telefonnummern aus!"];
  }

  if (contact.id === lastCallContact?.id) {
    return [true, ""];
  }

  const allowedStates = [...notReachedStates, "Verifikation ausstehend", "Kontaktstopp", "abgeschlossen"];

  if (contact.status && allowedStates.includes(contact.status.name)) {
    return [true, ""];
  }

  if (events.length > 0) {
    const now = new Date();
    const hasFutureEvent = events.some((e) => parsedDate(e.startTime) > now);
    if (hasFutureEvent) {
      return [true, ""];
    }
  }

  const toolTipText =
    "Bitte rufen Sie diesen Kontakt erst an oder setzen Sie ihn auf eine der folgenden Statusmöglichkeiten: „nicht erreichbar“, „Kontaktstopp“, „Verifikation ausstehend“";

  return [false, toolTipText];
};
