import { featureEnabled, isCustomerAdmin, isTermitel, projectFeatureEnabled, userFeatureEnabled } from "./helpers";

export interface ContactsMayInterface {
  (session: SessionInterface, path: "contacts"): boolean;
  (
    session: SessionInterface,
    path: "contacts",
    action: "list" | "new" | "agenda" | "import" | "batch" | "export" | "deleteAttachment" | "pipeline",
  ): boolean;
  (
    session: SessionInterface,
    path: "contacts",
    action: "show" | "edit" | "delete" | "transfer" | "delete-history" | "merge",
    contact: Nilable<BaseContactInterface>,
  ): boolean;

  // contact groups
  (session: SessionInterface, path: "contacts/groups"): boolean;
  (session: SessionInterface, path: "contacts/groups", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "contacts/groups",
    action: "show" | "edit" | "delete",
    group: ContactGroupInterface,
  ): boolean;

  // contacts/calls
  (session: SessionInterface, path: "contacts/calls"): boolean;
  (session: SessionInterface, path: "contacts/calls", action: "new" | "export"): boolean;
  (
    session: SessionInterface,
    path: "contacts/calls",
    action: "show",
    historyOrAnnotation: HistoryInterface | HistoryAnnotationInterface,
  ): boolean;

  (
    session: SessionInterface,
    path: "contacts/calls/punchlist",
    action: "new",
    punchlistEntry: Nullable<PunchlistEntryType>,
  ): boolean;

  (
    session: SessionInterface,
    path: "contacts/calls",
    action: "edit" | "delete" | "mark_done",
    annotation: HistoryAnnotationInterface,
  ): boolean;

  // contacts/agenda_configs
  (session: SessionInterface, path: "contacts/agenda_configs"): boolean;
  (session: SessionInterface, path: "contacts/agenda_configs", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "contacts/agenda_configs",
    action: "edit" | "delete",
    config: AgendaConfigType,
  ): boolean;

  // admin/contacts/addfields
  (session: SessionInterface, path: "admin/contacts/addfields"): boolean;
  (session: SessionInterface, path: "admin/contacts/addfields", action: "new" | "edit" | "delete"): boolean;

  // admin/contacts/contact-status-update
  (session: SessionInterface, path: "admin/contacts/contact-status-update"): boolean;
  (session: SessionInterface, path: "admin/contacts/contact-status-update", action: "new" | "edit" | "delete"): boolean;
}

const CONTACT_PERMISSIONS: PermissionType<BaseContactInterface> = {
  contacts: {
    index: ({ currentProject, currentUser }) => featureEnabled(currentUser, currentProject, "contacts"),

    list: ({ currentCustomer, currentProject, currentUser }) =>
      featureEnabled(currentUser, currentProject, "contacts") &&
      !(
        projectFeatureEnabled(currentProject, "contacts/hide-list-for-agents") &&
        isTermitel(currentUser) &&
        !isCustomerAdmin(currentUser, currentCustomer)
      ),

    new: ({ currentProject, currentUser }) =>
      featureEnabled(currentUser, currentProject, "contacts") &&
      !projectFeatureEnabled(currentProject, "contacts/prohibit-create"),
    edit: ({ currentProject, currentUser }) =>
      featureEnabled(currentUser, currentProject, "contacts") && userFeatureEnabled(currentUser, "contacts/edit"),
    agenda: ({ currentCustomer, currentProject, currentUser }) =>
      featureEnabled(currentUser, currentProject, "contacts") &&
      projectFeatureEnabled(currentCustomer, "contacts/priolists"),
    delete: ({ currentCustomer, currentProject, currentUser }) =>
      featureEnabled(currentUser, currentProject, "contacts") &&
      (isCustomerAdmin(currentUser, currentCustomer) || userFeatureEnabled(currentUser, "contacts/delete")),
    import: ({ currentCustomer, currentProject, currentUser }) =>
      featureEnabled(currentUser, currentProject, "contacts") && isCustomerAdmin(currentUser, currentCustomer),
    batch: ({ currentCustomer, currentProject, currentUser }) =>
      featureEnabled(currentUser, currentProject, "contacts") && isCustomerAdmin(currentUser, currentCustomer),
    export: ({ currentProject, currentUser }) =>
      featureEnabled(currentUser, currentProject, "contacts") && userFeatureEnabled(currentUser, "contacts/export"),
    merge: ({ currentCustomer, currentProject, currentUser }) =>
      featureEnabled(currentUser, currentProject, "contacts") &&
      (isCustomerAdmin(currentUser, currentCustomer) || userFeatureEnabled(currentUser, "contacts/merge")),
    "delete-history": ({ currentUser, currentCustomer }) => isCustomerAdmin(currentUser, currentCustomer),
    transfer: ({ currentCustomer, currentUser }) =>
      isCustomerAdmin(currentUser, currentCustomer) || userFeatureEnabled(currentUser, "contacts/change_owner"),
    deleteAttachment: ({ currentCustomer, currentUser }) =>
      isCustomerAdmin(currentUser, currentCustomer) || userFeatureEnabled(currentUser, "contacts/delete_attachment"),
    pipeline: ({ currentProject, currentUser }) =>
      isTermitel(currentUser) &&
      featureEnabled(currentUser, currentProject, "contacts") &&
      (projectFeatureEnabled(currentProject, "contacts/hide-list-for-agents") ||
        projectFeatureEnabled(currentProject, "contacts/pipeline")),
  },
};

const CONTACT_GROUP_PERMISSIONS: PermissionType<ContactGroupInterface> = {
  "contacts/groups": {
    index: ({ currentCustomer, currentProject, currentUser }) =>
      featureEnabled(currentUser, currentProject, "contacts") && isCustomerAdmin(currentUser, currentCustomer),
    edit: ({ currentCustomer, currentProject, currentUser }, { gtype }) =>
      featureEnabled(currentUser, currentProject, "contacts") &&
      isCustomerAdmin(currentUser, currentCustomer) &&
      gtype === "DYNAMIC",
  },
};

const CONTACT_CALL_PERMISSIONS: PermissionType<HistoryAnnotationInterface> = {
  "contacts/calls": {
    index: ({ currentProject, currentUser }) => featureEnabled(currentUser, currentProject, "contacts/calls"),
    export: ({ currentProject, currentUser }) =>
      featureEnabled(currentUser, currentProject, "contacts/calls") &&
      userFeatureEnabled(currentUser, "contacts/calls/export"),
    mark_done: ({ currentProject, currentUser }, resource) =>
      featureEnabled(currentUser, currentProject, "contacts/calls") && !resource.done,
  },

  "contacts/agenda_configs": {
    index: ({ currentCustomer, currentProject, currentUser }) =>
      featureEnabled(currentUser, currentProject, "contacts") && isCustomerAdmin(currentUser, currentCustomer),
  },
};

const CONTACT_CALL_PUNCHLIST_PERMISSIONS: PermissionType<PunchlistEntryType> = {
  "contacts/calls/punchlist": {
    new: ({ currentUser, currentCustomer, currentProject }, entry) =>
      featureEnabled(currentUser, currentProject, "contacts/calls") &&
      featureEnabled(currentUser, currentProject, "punchlist") &&
      (isCustomerAdmin(currentUser, currentCustomer) || userFeatureEnabled(currentUser, "punchlist/write")) &&
      !entry,
  },
};

const CONTACT_ADDFIELD_PERMISSIONS: PermissionType<ContactAddfieldInterface> = {
  "admin/contacts/addfields": {
    create: ({ currentCustomer, currentProject, currentUser }) =>
      featureEnabled(currentUser, currentProject, "contacts") && isCustomerAdmin(currentUser, currentCustomer),
    edit: ({ currentCustomer, currentProject, currentUser }) =>
      featureEnabled(currentUser, currentProject, "contacts") && isCustomerAdmin(currentUser, currentCustomer),
    delete: ({ currentCustomer, currentProject, currentUser }) =>
      featureEnabled(currentUser, currentProject, "contacts") && isCustomerAdmin(currentUser, currentCustomer),
  },
};

const CONTACT_STATUS_UPDATE_PERMISSIONS: PermissionType<ContactStatusUpdateInterface> = {
  "admin/contacts/contact-status-update": {
    create: ({ currentCustomer, currentProject, currentUser }) =>
      featureEnabled(currentUser, currentProject, "contacts") && isCustomerAdmin(currentUser, currentCustomer),
    edit: ({ currentCustomer, currentProject, currentUser }) =>
      featureEnabled(currentUser, currentProject, "contacts") && isCustomerAdmin(currentUser, currentCustomer),
    delete: ({ currentCustomer, currentProject, currentUser }) =>
      featureEnabled(currentUser, currentProject, "contacts") && isCustomerAdmin(currentUser, currentCustomer),
  },
};

const ContactPermissions = {
  ...CONTACT_PERMISSIONS,
  ...CONTACT_GROUP_PERMISSIONS,
  ...CONTACT_CALL_PERMISSIONS,
  ...CONTACT_ADDFIELD_PERMISSIONS,
  ...CONTACT_STATUS_UPDATE_PERMISSIONS,
  ...CONTACT_CALL_PUNCHLIST_PERMISSIONS,
};

export default ContactPermissions;
