import clsx from "clsx";
import { getIn, useFormikContext } from "formik";

export default function ErrorMessage({
  path,
  className,
  showUntouched = false,
}: {
  path: string;
  className?: string;
  showUntouched?: boolean;
}) {
  const { errors, touched } = useFormikContext();
  const error = getIn(errors, path);

  if (!error || (!getIn(touched, path) && !showUntouched)) {
    return null;
  }

  return <div className={clsx("TT-error-feedback", className)}>{error}</div>;
}
