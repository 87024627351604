import { useState } from "react";

import { useMutation } from "@apollo/client";
import { Form, Formik, type FormikHelpers } from "formik";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MdReportProblem } from "react-icons/md";
import type { Descendant } from "slate";
import * as yup from "yup";

import { TICKET_MUTATION } from "@api/tickets";

import { may } from "../../../abilities";
import { CancelButton, SaveButton } from "../../../containers/buttons";
import handleError from "../../../handleError";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { sessionSelector } from "../../../store/sessionReducer";
import { addDangerFlash, addSuccessFlash } from "../../flash/flashReducer";
import TTModal from "../../TTModal";
import Inner from "./Inner";

export type ValuesType = {
  subject: string;
  status: TicketStatusType;
  category: TicketCategoryType;
  priority: number;
  ownerId: string;
  description: string;
  visible: boolean;
  document: {
    content: Descendant[];
  };
  attrs: {
    problemType: string;
    workstation: string;
  };
};

const INITIAL_VALUES: ValuesType = {
  subject: "",
  status: "NEW",
  category: "TECHNICAL_PROBLEM",
  priority: 2,
  ownerId: "2",
  visible: true,
  attrs: {
    problemType: "",
    workstation: "",
  },
  description: "",
  document: {
    content: [],
  },
};

const validationSchema = yup.object({
  subject: yup.string().required("Bitte geben Sie einen Betreff ein"),
  attrs: yup.object({
    problemType: yup.string().required("Bitte wählen Sie ein Problem aus"),
    workstation: yup.string().required("Bitte geben Sie die Workstation an"),
  }),
});

export default function ITProblemModal() {
  const session = useAppSelector(sessionSelector);
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);
  const { t } = useTranslation(["translation"]);

  const [mutateTicket] = useMutation<TicketMutationInterface>(TICKET_MUTATION);

  async function createTicket(values: ValuesType, { setSubmitting }: FormikHelpers<ValuesType>) {
    try {
      await mutateTicket({
        variables: {
          customerId: "1",
          values: {
            ...values,
            watchers: ["4", "1785"],
          },
        },
      });

      dispatch(addSuccessFlash("Das Problem wurde erfolgreich gemeldet!"));
      setShow(false);
    } catch (error) {
      setSubmitting(false);
      dispatch(addDangerFlash(t("translation:global.general_error")));
      handleError(error);
    }
  }

  function hideModal() {
    setShow(false);
  }

  if (!may(session, "tickets", "new") || session.currentUser.customerId !== "1") {
    return null;
  }

  return (
    <>
      <button type="button" onClick={() => setShow(true)} title="IT-Problem melden">
        <MdReportProblem />
      </button>

      <TTModal size="lg" show={show} onHide={hideModal}>
        <Formik initialValues={INITIAL_VALUES} validationSchema={validationSchema} onSubmit={createTicket}>
          {() => (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>IT-Problem melden</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <Inner />
              </Modal.Body>

              <Modal.Footer>
                <SaveButton type="submit">Problem melden</SaveButton>
                <CancelButton type="button" onClick={hideModal}>
                  abbrechen
                </CancelButton>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </TTModal>
    </>
  );
}
