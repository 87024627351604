import { featureEnabled, isRoot } from "./helpers";

export interface FileExchangeMayInterface {
  (session: SessionInterface, path: "file-exchange"): boolean;
  (session: SessionInterface, path: "file-exchange", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "file-exchange",
    action: "show" | "edit" | "delete" | "import",
    file: FileExchangeFile,
  ): boolean;
}

const FILE_EXCHANGE_PERMISSIONS: PermissionType<FileExchangeFile> = {
  "file-exchange": {
    index: ({ currentProject, currentUser }) => featureEnabled(currentUser, currentProject, "file_exchange"),
    import: ({ currentProject, currentUser }, file) =>
      featureEnabled(currentUser, currentProject, "file_exchange") &&
      isRoot(currentUser) &&
      file.filename.endsWith(".pdf"),
  },
};

export default FILE_EXCHANGE_PERMISSIONS;
