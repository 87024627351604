export interface MessageMayInterface {
  (session: SessionInterface, path: "messages"): boolean;
  (session: SessionInterface, path: "messages", action: "new"): boolean;
  (session: SessionInterface, path: "messages", action: "show" | "edit" | "delete", group: MessageInterface): boolean;
}

const MessagePermissions: PermissionType<MessageInterface> = {
  messages: {
    index: (_session, _message) => true,
    show: ({ currentUser }, message) => currentUser.id === message.userId,
    edit: ({ currentUser }, message) => currentUser.id === message.userId,
    delete: ({ currentUser }, message) => currentUser.id === message.userId,
  },
};

export default MessagePermissions;
