import _ from "lodash";

import { queryString, sessionPrefix } from "./helpers";

const getId = (cnt: BaseContactInterface | string): string => {
  if (_.isObject(cnt)) {
    return cnt.parentId || cnt.id;
  }
  return cnt;
};

export const contactsPath = (session: LightSessionInterface, query: QueryStringType = null) =>
  `${sessionPrefix(session)}/contacts${queryString(query)}`;
export const newContactPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/contacts/new`;
export const showContactPath = (
  session: LightSessionInterface,
  contact: BaseContactInterface | string,
  qs: QueryStringType = null,
) => `${sessionPrefix(session)}/contacts/${getId(contact)}${queryString(qs)}`;

export const showContactPathWoSession = (contact: BaseContactInterface) =>
  `/${contact.customer.identifier}/${contact.project.identifier}/contacts/${contact.id}`;

export const noContactsPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/contacts/no-contacts`;

export const contactsAgendaPath = (session: LightSessionInterface, query: QueryStringType = null) =>
  `${sessionPrefix(session)}/contacts/agenda${queryString(query)}`;

export const importContactsPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/contacts/import`;

export const exportContactsPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/contacts/export`;

export const contactGroupsPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/contacts/groups`;
export const newContactGroupPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/contacts/groups/new`;
export const showContactGroupPath = (session: LightSessionInterface, group: ContactGroupInterface) =>
  `${sessionPrefix(session)}/contacts/groups/${group.id}`;
export const editContactGroupPath = (session: LightSessionInterface, group: ContactGroupInterface) =>
  `${sessionPrefix(session)}/contacts/groups/${group.id}/edit`;

export const contactCallsPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/contacts/calls`;
export const showContactCallPath = (session: LightSessionInterface, history: HistoryInterface) =>
  `${sessionPrefix(session)}/contacts/calls/${history.id}`;
export const showContactCallPathById = (session: LightSessionInterface, historyId: string) =>
  `${sessionPrefix(session)}/contacts/calls/${historyId}`;

export const contactsBatchAction = (session: LightSessionInterface) => `${sessionPrefix(session)}/contacts/batch`;
export const contactsBatchActionDelete = (session: LightSessionInterface) =>
  `${sessionPrefix(session)}/contacts/batch/delete`;
export const contactsBatchActionSetField = (session: LightSessionInterface) =>
  `${sessionPrefix(session)}/contacts/batch/set-field`;
export const contactsBatchActionTransfer = (session: LightSessionInterface) =>
  `${sessionPrefix(session)}/contacts/batch/transfer`;

export const contactAgendaConfigsPath = (session: LightSessionInterface) =>
  `${sessionPrefix(session)}/contacts/agenda-configs`;
export const newContactAgendaConfigPath = (session: LightSessionInterface) =>
  `${sessionPrefix(session)}/contacts/agenda-configs/new`;
export const editContactAgendaConfigPath = (session: LightSessionInterface, config: AgendaConfigType) =>
  `${sessionPrefix(session)}/contacts/agenda-configs/${config.id}`;
