import { getIn } from "formik";

import { ReactLazyLoading } from "../../containers/ReactLoading";

const COMPONENTS = {
  "sevenit-gmbh": {
    default: {
      list: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/support/List")),
      show: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/support/Show")),
      form: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/support/Form")),
    },
    service: {
      list: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/service/List")),
      form: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/support/Form")),
      show: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/service/Show")),
    },
    sales: {
      form: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/sales/Form")),
      list: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/sales/List")),
      show: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/sales/Show")),
    },
    "upgrade-plan-b": {
      form: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/UpgradePlanB/Form")),
      list: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/UpgradePlanB/List")),
      show: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/UpgradePlanB/Show")),
    },
    retention: {
      form: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/retention/Form")),
      show: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/retention/Show")),
      list: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/retention/List")),
    },
    "sevdesk-test": {
      list: ReactLazyLoading(() => import("../../customers/sevenit/CustomerContacts/TestProject/List")),
    },
  },

  "sk-dienstleistungen": {
    default: {
      list: ReactLazyLoading(() => import("../../customers/sk/List")),
      show: ReactLazyLoading(() => import("../../customers/sk/Show")),
      form: ReactLazyLoading(() => import("../../customers/sk/Form")),
    },
  },

  smr: {
    default: {
      list: ReactLazyLoading(() => import("../../customers/smr/CustomerContacts/List")),
      form: ReactLazyLoading(() => import("../../customers/smr/CustomerContacts/Form")),
      show: ReactLazyLoading(() => import("../../customers/smr/CustomerContacts/Show")),
    },
  },

  "lr-health-beauty": {
    service: {
      form: ReactLazyLoading(() => import("../../customers/lr-health-beauty/CustomerContacts/service/Form")),
      show: ReactLazyLoading(() => import("../../customers/lr-health-beauty/CustomerContacts/service/Show")),
      list: ReactLazyLoading(() => import("../../customers/lr-health-beauty/CustomerContacts/service/List")),
      products: ReactLazyLoading(() => import("./Offers/Products")),
    },
    "outbound-exit": {
      form: ReactLazyLoading(() => import("../../customers/lr-health-beauty/CustomerContacts/outbound-exit/Form")),
      show: ReactLazyLoading(() => import("../../customers/lr-health-beauty/CustomerContacts/outbound-exit/Show")),
      list: ReactLazyLoading(() => import("../../customers/lr-health-beauty/CustomerContacts/outbound-exit/List")),
    },
    "lr-uk": {
      form: ReactLazyLoading(() => import("../../customers/lr-health-beauty/CustomerContacts/lr-uk/Form")),
      show: ReactLazyLoading(() => import("../../customers/lr-health-beauty/CustomerContacts/lr-uk/Show")),
      list: ReactLazyLoading(() => import("../../customers/lr-health-beauty/CustomerContacts/lr-uk/List")),
    },
    "service-uk": {
      form: ReactLazyLoading(() => import("../../customers/lr-health-beauty/CustomerContacts/uk-service/Form")),
      show: ReactLazyLoading(() => import("../../customers/lr-health-beauty/CustomerContacts/uk-service/Show")),
      list: ReactLazyLoading(() => import("../../customers/lr-health-beauty/CustomerContacts/uk-service/List")),
    },
  },

  "lr-france": {
    frankreich: {
      form: ReactLazyLoading(() => import("../../customers/lr-france/CustomerContacts/frankreich/Form")),
      show: ReactLazyLoading(() => import("../../customers/lr-france/CustomerContacts/frankreich/Show")),
      list: ReactLazyLoading(() => import("../../customers/lr-france/CustomerContacts/frankreich/List")),
    },
    service: {
      form: ReactLazyLoading(() => import("../../customers/lr-france/CustomerContacts/service/Form")),
      show: ReactLazyLoading(() => import("../../customers/lr-france/CustomerContacts/service/Show")),
      list: ReactLazyLoading(() => import("../../customers/lr-france/CustomerContacts/service/List")),
    },
  },

  proxytel: {
    vertrieb: {
      form: ReactLazyLoading(() => import("../../customers/proxytel/vertrieb/Tasks/Form")),
      show: ReactLazyLoading(() => import("../../customers/proxytel/vertrieb/Tasks/Show")),
      list: ReactLazyLoading(() => import("../../customers/proxytel/vertrieb/Tasks/List")),
    },
  },

  carprotec: {
    support: {
      form: ReactLazyLoading(() => import("../../customers/carpro-tec/Tasks/Support/Form")),
      show: ReactLazyLoading(() => import("../../customers/carpro-tec/Tasks/Support/Show")),
      list: ReactLazyLoading(() => import("../../customers/carpro-tec/Tasks/Support/List")),
    },
    "amg-line": {
      form: ReactLazyLoading(() => import("../../customers/carpro-tec/Tasks/AmgLine/Form")),
      show: ReactLazyLoading(() => import("../../customers/carpro-tec/Tasks/AmgLine/Show")),
      list: ReactLazyLoading(() => import("../../customers/carpro-tec/Tasks/AmgLine/List")),
    },
  },

  "mercedes-benz": {
    default: {
      form: ReactLazyLoading(() => import("../../customers/mercedes-benz/Tasks/Form")),
      show: ReactLazyLoading(() => import("../../customers/mercedes-benz/Tasks/Show")),
      list: ReactLazyLoading(() => import("../../customers/mercedes-benz/Tasks/List")),
    },
  },

  zimmermann: {
    default: {
      form: ReactLazyLoading(() => import("../../customers/zimmermann/tasks/Form")),
      show: ReactLazyLoading(() => import("../../customers/zimmermann/tasks/Show")),
      list: ReactLazyLoading(() => import("../../customers/zimmermann/tasks/List")),
    },
  },

  termitel: {
    vertrieb: {
      form: ReactLazyLoading(() => import("../../customers/termitel/Vertrieb/Tasks/Form")),
      show: ReactLazyLoading(() => import("../../customers/termitel/Vertrieb/Tasks/Show")),
      list: ReactLazyLoading(() => import("../../customers/termitel/Vertrieb/Tasks/List")),
    },
  },

  demo: {
    default: {
      form: ReactLazyLoading(() => import("../../customers/demo/Tasks/Form")),
      show: ReactLazyLoading(() => import("../../customers/demo/Tasks/Show")),
      list: ReactLazyLoading(() => import("../../customers/demo/Tasks/List")),
    },
  },

  cityclean: {
    default: {
      form: ReactLazyLoading(() => import("../../customers/cityclean/Tasks/Form")),
      show: ReactLazyLoading(() => import("../../customers/cityclean/Tasks/Show")),
      list: ReactLazyLoading(() => import("../../customers/cityclean/Tasks/List")),
    },
  },

  default: {
    list: ReactLazyLoading(() => import("./List")),
    new: ReactLazyLoading(() => import("./New")),
    edit: ReactLazyLoading(() => import("./Edit")),
    show: ReactLazyLoading(() => import("./Show")),
    form: ReactLazyLoading(() => import("./Form")),
    export: ReactLazyLoading(() => import("./Export")),
    offers: ReactLazyLoading(() => import("./Offers")),
  },
};

export const getComponent = (customer: CustomerInterface, project: BaseProjectInterface, type: string) =>
  getIn(COMPONENTS, [customer.identifier, project.identifier, type]) ||
  getIn(COMPONENTS, [customer.identifier, "default", type]) ||
  getIn(COMPONENTS, ["default", type]) ||
  null;
