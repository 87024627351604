import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { endOfISOWeek, startOfISOWeek } from "date-fns";

import type { RootState } from "@/store";

export interface SickNotesReducerInterface {
  user: Nullable<UserInterface>;
  starts: Nullable<Date>;
  ends: Nullable<Date>;
  status: SickNoteStatus[];
}

const initialState: SickNotesReducerInterface = {
  user: null,
  status: ["NEW", "CERTIFICATE_WANTED", "CERTIFICATE_GIVEN"],
  starts: startOfISOWeek(new Date()),
  ends: endOfISOWeek(new Date()),
};

const sickNotesSlice = createSlice({
  name: "sickNotes",
  initialState,
  reducers: {
    resetFilters(state) {
      state.status = ["NEW", "CERTIFICATE_WANTED", "CERTIFICATE_GIVEN"];
      state.starts = startOfISOWeek(new Date());
      state.ends = endOfISOWeek(new Date());
      state.user = null;
    },

    setStatus(state, action: PayloadAction<SickNoteStatus[]>) {
      state.status = action.payload;
    },
    setStarts(state, { payload }: PayloadAction<Nullable<Date>>) {
      state.starts = payload;
    },
    setEnds(state, { payload }: PayloadAction<Nullable<Date>>) {
      state.ends = payload;
    },
    setUser(state, { payload }: PayloadAction<Nullable<UserInterface>>) {
      state.user = payload;
    },
  },
});

export const { resetFilters, setStatus, setStarts, setEnds, setUser } = sickNotesSlice.actions;
export const sickNotesSelector = (state: RootState) => state.sickNotes;
export default sickNotesSlice.reducer;
